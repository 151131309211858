import axios from "axios";
import { toast } from "react-toastify"; //toast notifications
import publicIp from "react-public-ip";

//const ipv6 = await publicIp.v6() || "";
//import * as Sentry from "@sentry/browser";
//getIp();
function setBaseURL(ip) {
  //toast.info("processEnv " + process.env.REACT_APP_PUBLIC_IP);
  // toast.info("ipType " + typeof ip);
  if (process.env.REACT_APP_PUBLIC_IP) {
    if (process.env.REACT_APP_PUBLIC_IP.split(",").includes(ip)) {
      axios.defaults.baseURL = process.env.REACT_APP_API_URL;
      // toast.info("baseURL: " + axios.defaults.baseURL);
    } else {
      axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API_URL;
      //toast.info("public baseURL: " + axios.defaults.baseURL);
    }
  } else {
    toast.error("Ip Configuration Error, Please Restart Your App");
    axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API_URL;
  }
}
axios.interceptors.response.use(null, (error) => {
  // to Intercept Unexpected Errors and display generic error, //SucessfulResponse=null, Error
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    // this means its unexpected Error

    //Sentry.captureException("HttpService is Logging the error:## ");

    // console.log(error);
    // toast.error("An Unexpected Error Occured."); // remove error to display a friendly  msg
    toast.error(error.message, {
      toastId: "UnExpectedError",
    });

    // if (!toast.isActive(toastId.current)) {
    //   toastId.current = toast.error(error.message);
    // }
  }

  return Promise.reject(error);
});

function setJWT(jwt) {
  //configuring default headers
  axios.defaults.headers.common["x-auth-token"] = jwt;
}
async function getIp() {
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      ) ||
      window.location.hostname.match(
        /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
  const isLocalDomain = Boolean(
    window.location.hostname.includes("test.") ||
      window.location.hostname.includes("local.") ||
      window.location.hostname.includes("dev.")
  );

  if (isLocalhost) axios.defaults.baseURL = process.env.REACT_APP_LOCAL_API_URL;
  if (isLocalDomain)
    axios.defaults.baseURL = process.env.REACT_APP_LOCAL_DOMAIN_API_URL;

  //toast.info("IP Configed: " + axios.defaults.baseURL);
  console.log("isLocalhost", isLocalhost);
  console.log("isLocalDomain", isLocalDomain);

  console.log(axios.defaults.baseURL);
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJWT,
  getIp,
};
