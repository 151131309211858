import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  GetStockUpdateHistoryHistory,
  GetVendorNames,
  getStockUpdateHistory,
  getVendorNames,
  getRecentStockUpdate,
  GetRecentStockUpdate,
} from "../../../store/stockUpdateDuxm";
import Select from "react-select";
import { useState } from "react";
import { getLocalFormattedDateTime } from "../../../utils/myDateTime";
export default function History({ showHistory, setShowHistory }) {
  const dispatch = useDispatch();

  let historyData = GetStockUpdateHistoryHistory();

  if (!historyData) {
    historyData = [];
  }

  const venderNames = GetVendorNames() || [];
  const [selectedVendorName, setSelectedVendorName] = useState("");
  const recentStockUpdateDate = GetRecentStockUpdate() || {};
  if (selectedVendorName) {
    historyData = historyData.filter(
      (entry) => entry.vendor_name === selectedVendorName
    );
  }

  const filterWithVendorName = (vendorName) => {
    setSelectedVendorName(vendorName?.value);
    // return historyData.filter(
    //   (entry) => entry.vendor_name === vendorName.value
    // )
  };

  const groupedData = {};

  // Group data by bill number
  historyData.forEach((entry) => {
    const { bill_number } = entry;
    console.log("history entry", entry);
    if (!groupedData[bill_number]) {
      groupedData[bill_number] = [];
    }
    groupedData[bill_number].push(entry);
  });

  // Sort each group by update_date_time
  for (const billNumber in groupedData) {
    groupedData[billNumber].sort((a, b) => {
      const dateA = new Date(a.update_date_time);
      const dateB = new Date(b.update_date_time);
      return dateA - dateB;
    });
  }

  function convertDateToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [selectedDate, setSelectedDate] = React.useState("");
  const handleChange = (date) => {
    console.log("date", date);
    setSelectedDate(date);
    dispatch(getStockUpdateHistory(convertDateToYYYYMMDD(date)));
  };

  useEffect(() => {
    dispatch(getVendorNames());
    dispatch(getRecentStockUpdate());
    dispatch(
      getStockUpdateHistory(convertDateToYYYYMMDD(recentStockUpdateDate))
    );
    setSelectedDate(new Date(recentStockUpdateDate));
  }, [dispatch, recentStockUpdateDate]);

  const vendorOpts = venderNames.map((vendor) => ({
    value: vendor,
    label: vendor,
  }));

  return (
    <div>
      <Modal
        className="modal-dialog modal-dialog-centered"
        show={showHistory}
        onHide={() => setShowHistory(false)}
        style={{}}
      >
        <Modal.Header closeButton>
          <Modal.Title>History of bills</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ position: "relative", zoom: "0.75", padding: 0 }}>
          <div className="row justify-content-around">
            <DatePicker
              onChange={handleChange}
              selected={selectedDate}
              // placeholderText={convertDateToYYYYMMDD(recentStockUpdateDate)}
              placeholderText={selectedDate}
              dateFormat="dd/MM/yyyy"
            />
            <Select
              options={vendorOpts}
              placeholder="select vendor name"
              onChange={filterWithVendorName}
              isClearable={true}
            />
          </div>

          <div
            style={{ maxHeight: "600px", overflow: "auto", minHeight: "200px" }}
          >
            {historyData[0]?.date == "No history found" ? (
              <div className="m-5">
                No history found on {convertDateToYYYYMMDD(selectedDate)}
              </div>
            ) : (
              Object.keys(groupedData).map((m, index) => {
                console.log("m>>", m);
                return (
                  <table
                    className="table p-2 table-bordered table-hover"
                    id="table"
                    data-toggle="table"
                    data-url="json/data5.json"
                    data-height="460"
                    data-show-columns="true"
                  >
                    <thead>
                      <tr>
                        <th colSpan={1}>Bill Number</th>
                        <th style={{ textAlign: "center" }}>
                          {/* key of the groupedData object */}
                          {m}
                        </th>
                        <th></th>
                        <th colSpan={1}>Updated At</th>
                        <th colSpan={3} style={{ textAlign: "center" }}>
                          {/* key of the groupedData object */}
                          {getLocalFormattedDateTime(
                            groupedData[m][0].update_date_time
                          )}
                        </th>
                      </tr>
                      <tr className="thead-light">
                        <th data-field="sq">SQ</th>
                        <th data-field="id">ID</th>
                        <th data-field="name">NAME</th>
                        <th data-field="vendor">VENDOR </th>
                        <th data-field="qty">CURRENT QTY</th>
                        <th data-field="qty">QTY TO ADD</th>
                        <th data-field="qty">TOTAL QTY</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedData[m].map((m, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{m.item_id}</td>
                          <td>{m.item_name}</td>
                          <td>{m.vendor_name}</td>
                          <td>{m.item_qty_before}</td>
                          <td>{m.item_qty_to_add}</td>
                          <td>{m.item_qty_after}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              })
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
