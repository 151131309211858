import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Form from "../../common/form";
import { confirm } from "../../common/confirmationAlert";
import Joi from "joi-browser";
import { getCurrentDate, getFormattedDate } from "../../../utils/myDateTime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { getItemData } from "../../../services/itemDataService";
import _ from "lodash";
import { addNewItem, updateItem } from "../../../store/itemsDux";
import { getRackDefinitions } from "../../../store/racksDux";
import { Modal } from "react-bootstrap";
import '../../../assets/css/index.css'
class AddNewItem extends Form {
  constructor(props) {
    super(props);
    this.state.data = props.newBatch;
  }
  state = {
    // allItems: [],
    data: {
      NAME: "",
      CONTENT: "",
      RACK_LOCATION: "",
      STOCK_LOCATION: "",
      TYPE: "",
      MANUFACTURER: "",
      BATCH_NO: "",
      EXP: "",
      VENDOR: "",
      STRIPS_IN_BOX: "",
      TABLETS_IN_ONE_STRIP: "",
      QTY: "",
      REORDER_QTY: "",
      MRP: "",
      PURCHASE_PRICE: "",
      SELLING_PRICE: "",
    },

    startDate: "",
    selectedEXP: "",
    loose_qty: 0,

    errors: {}, // simplified errors object which will map the complex JOI Object
    errorMessage: {
      NAME: "Name",
      CONTENT: "Content",
      RACK_LOCATION: "Rack Location",
      STOCK_LOCATION: "Stock Location",
      TYPE: "Type",
      MANUFACTURER: "Manufacturer",
      BATCH_NO: "Batch Number",
      EXP: "EXP",
      VENDOR: "Vendor",
      STRIPS_IN_BOX: "Strips in Box",
      TABLETS_IN_ONE_STRIP: "Tablets In One Strip",
      QTY: "Qty",
      REORDER_QTY: "Reorder Qty",
      MRP: "MRP",
      PURCHASE_PRICE: "Purchase Price",
      SELLING_PRICE: "Selling Price",
    },

    STOCK_LOCATION: getItemData().STOCK_LOCATION,
    RACK_LOCATION: getItemData().RACK_LOCATION,
    TYPE: getItemData().TYPE,
    defaultType: getItemData().defaultType[0],

    //this is to hide erroe msg until we hit button
    isRequiredFilled: this.state.isRequiredFilled,
  };

  schema = {
    ID: Joi.number(),
    NAME: Joi.string()
      .regex(/[a-zA-Z]/)
      .required(),
    CONTENT: Joi.string().required(),
    RACK_LOCATION: Joi.string().required(),
    STOCK_LOCATION: Joi.string().required(),
    TYPE: Joi.string().required(),
    MANUFACTURER: Joi.string()
      .regex(/[a-zA-Z0-9]/)
      .required(),
    BATCH_NO: Joi.string().max(10).required(),
    EXP: Joi.string().max(10).required(),
    VENDOR: Joi.string().allow(null, "null", ""),
    STRIPS_IN_BOX: Joi.number(),
    TABLETS_IN_ONE_STRIP: Joi.number(),
    QTY: Joi.number().required(),
    LOOSE_QTY: Joi.number(),
    REORDER_QTY: Joi.number().required(),
    MRP: Joi.number().max(99999).required(),
    PURCHASE_PRICE: Joi.number().max(99999).required(),
    SELLING_PRICE: Joi.number().max(99999).required(),
  };

  // componentDidMount() {
  //   this.props.getRackDefinitions();
  // }

  componentWillReceiveProps(props) {
    if (!_.isEmpty(props.newBatch)) {
      this.setState({ data: props.newBatch });
      let EXP = new Date();
      let oldEXP = props.newBatch.EXP.split("-");
      EXP.setFullYear(oldEXP[2], oldEXP[1], oldEXP[0]);
      EXP.setMonth(EXP.getMonth() - 1);

      this.setState({
        selectedEXP: EXP,
        loose_qty: parseInt(props.newBatch.LOOSE_QTY),
      });

      props.columns.forEach((c) => {
        if (c.accessor == "LOOSE_QTY") {
          props.columns.pop(c);
        }
      });

      if (props.newBatch.LOOSE_QTY)
        props.columns.push({
          Header: () => <b>{"LOOSE_QTY"}</b>,
          accessor: "LOOSE_QTY",
          show: true,
          width: 30,
          // getColumnWidth(this.state.data, `${c.name}`),
        });
      console.log("props.columns>>>");

      console.log(props.columns);
    } else {
      console.log("props.newBatch>>>");

      console.log(props.newBatch);
    }
  }

  handleEXPChange = (date) => {
    this.setState({ selectedEXP: date });
    let data = this.state.data;
    data.EXP = getFormattedDate(date);
    this.setState({ data });
  };
  checkIfItemExists = () => {
    const { NAME: inputName, BATCH_NO: inputBatchNo } = this.state.data;
    console.log(inputName);
    if (this.props.allItems.length > 1) {
      console.log("all Items in props: ");
      console.log(this.props.allItems);
      const items = this.props.allItems.filter(
        (ai) =>
          ai.item_name.split(" ").join("").toUpperCase() ===
          inputName.split(" ").join("").toUpperCase()
      );
      //console.log(ai.NAME.strip().toUpperCase());
      console.log("checking if item exists..");
      console.log(items);
      if (items.length < 1) {
        return {
          ...this.state.data,
          CREATION_DATE: getCurrentDate().toString(),
        };
      } else {
        console.log("item already there in our DB");
        //check if batch numbers are same
        let notInDB = true;
        items.forEach((item) => {
          if (
            item.item_batch_no === null
              ? "null" === inputBatchNo
              : item.item_batch_no === inputBatchNo
          ) {
            //if yes update item in db
            //confirm update
            confirm({
              message: "Do you want to update Item or add a new Batch?",
              buttons: ["Update Item", "AddNewBatch"],
            }).then(({ button }) => {
              if (button === "Update Item") {
                console.log("Add Loose QTY to QTY>>>>>");
                console.log(this.state.data.QTY);
                console.log(this.state.loose_qty);
                //update item

                let updatedItem = {
                  ID: item.item_id,
                  EXP: `${item.item_exp.split("-")[2]}-${
                    item.item_exp.split("-")[1]
                  }-${item.item_exp.split("-")[0]}`,
                  ...this.state.data,
                  CREATION_DATE: item.item_creation_date,
                };

                console.log("updateItem BEFORE>>>");
                console.log(updatedItem);
                this.props.updateNewBatchData(updatedItem);
                updatedItem = this.mapToViewModelOutwards(updatedItem);

                console.log("updateItem AFTER>>>");
                console.log(updatedItem);

                this.props.updateItem(updatedItem);
                // this.setState({data:})
              }
              if (button === "AddNewBatch") {
                toast.error(
                  `Batch Number Already there in Database, please update it.`
                );
                return;
              }
            });
            notInDB = false;
          }
        });
        //if no, add new batch
        if (notInDB) {
          return {
            ...this.state.data,
            CREATION_DATE: getCurrentDate().toString(),
          };
        }
      } //Need to assign data here
    }
  };

  mapToViewModelOutwards = (item) => {
    return {
      item_id: item.ID,
      item_name: item.NAME,
      item_content: item.CONTENT,
      item_rack_location: item.RACK_LOCATION,
      item_stock_location: item.STOCK_LOCATION,
      item_type: item.TYPE,
      item_manufacturer: item.MANUFACTURER,
      item_batch_no: item.BATCH_NO,
      item_exp: item.EXP,
      item_vendor: item.VENDOR,
      strips_in_box: item.STRIPS_IN_BOX,
      tablets_in_one_strip: item.TABLETS_IN_ONE_STRIP,
      item_qty: (
        parseInt(item.QTY) * parseInt(item.TABLETS_IN_ONE_STRIP) +
        parseInt(this.state.loose_qty)
      ).toString(),
      item_reorder_qty: item.REORDER_QTY,
      item_mrp: item.MRP,
      item_purchase_price: item.PURCHASE_PRICE,
      item_selling_price: item.SELLING_PRICE,
      item_creation_date:
        item.CREATION_DATE === undefined
          ? getCurrentDate().toString()
          : item.CREATION_DATE,
    };
  };
  doSubmit = async () => {
    let datachecked = this.checkIfItemExists();
    if (_.isEmpty(datachecked)) return;
    this.props.updateNewBatchData(datachecked);
    datachecked = this.mapToViewModelOutwards(datachecked);
    console.log("datachecked, Adding New Item/Batch>>>");
    console.log(datachecked);
    this.props.addingNewItem(datachecked);
  };

  
  // const [showPopup, setShowPopup] = useState(true);
  render() {
    return (
      <React.Fragment>
        <Modal
          className="modal-dialog modal-dialog-centered"
          show={this.props.showPopup}
          onHide={() => this.props.updateShowPopup(false)} 
          style={{}}
        >
          <Modal.Header closeButton>
            <Modal.Title>ADD OR UPDATE ITEM HERE!!</Modal.Title>
          </Modal.Header>
        
          <Modal.Body
            style={{ position: "relative", zoom:"0.8",height:"700px",overflow:"scroll", }} 
            // scroll is not showing always show it
            overflow="scroll"
          >
        <form onSubmit={this.handleSubmit}>
          <div className=" align-items-center p-2">
            {this.props.columns.reduce((result, c) => {
              c = {
                Header: `${c.accessor}`,
                accessor: c.accessor,
                show: c.show,
                width: c.width,
              };
              if (
                c.Header !== "EDIT" &&
                c.Header !== "UPDATE" &&
                c.Header !== "ID" &&
                c.Header !== "ATC" &&
                c.Header !== "DELETE" &&
                c.Header !== "ADD" &&
                c.Header !== "ADDQTY" &&
                c.Header !== "CREATION_DATE"
              ) {
                if (
                  c.Header !== "RACK_LOCATION" &&
                  c.Header !== "STOCK_LOCATION" &&
                  c.Header !== "TYPE" &&
                  c.Header !== "ADDQTY" &&
                  c.Header !== "EXP"
                  // &&
                  // c.Header !== "PURCHASE_PRICE" &&
                  // c.Header !== "SELLING_PRICE" &&
                  // c.Header !== "QTY" &&
                  // c.Header !== "REORDER_QTY"
                  //Added only not to have sunil  add it// Need to add them later once all stock is added
                ) {
                  let Header = "";
                  if (
                    c.Header === "QTY"
                      ? (Header = "QTY (strips)")
                      : c.Header === "LOOSE_QTY"
                      ? (Header = "LOOSE_QTY ")
                      : c.Header === "REORDER_QTY"
                      ? (Header = "REORDER_QTY (in strips)")
                      : c.Header === "MRP"
                      ? (Header = "MRP(per strip)")
                      : c.Header === "PURCHASE_PRICE"
                      ? (Header = "PURCHASE_PRICE (per strip)")
                      : c.Header === "SELLING_PRICE"
                      ? (Header = "SELLING_PRICE (per strip)")
                      : (Header = c.Header)
                  )
                    result.push(
                      <div key={c.Header} className="form-group">
                        {this.renderInput(c.Header, `${Header}`)}
                      </div>
                    );
                }

                if (c.Header === "EXP") {
                  result.push(
                    <div key={c.Header} className="">
                      <div className="form-group" style={{ display: "grid" }}>
                        <label htmlFor={c.Header}>
                          <b>{c.Header}</b>
                        </label>
                        <DatePicker
                          selected={this.state.selectedEXP}
                          onChange={this.handleEXPChange}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        />
                      </div>
                    </div>
                  );
                }

                if (
                  c.Header === "RACK_LOCATION" ||
                  c.Header === "STOCK_LOCATION" ||
                  c.Header === "TYPE"
                ) {
                  result.push(
                    <div key={c.Header} className="">
                      {this.renderSelect(
                        c.Header,
                        `${c.Header}`,
                        this.state[c.Header],
                        this.state.data[c.Header]
                      )}
                    </div>
                  );
                }
              }

              return result;
            }, [])}
          </div>
          <div className="column align-items-center mx-2">
            <div className="row">
              {this.renderButton("Submit", "btn btn-primary btn-lg btn-block")}
            </div>
            <div className="row pt-1">
              {this.renderErrorMessage(this.state.errorMessage)}
            </div>
          </div>
        </form>
        </Modal.Body>
        </Modal>
      </React.Fragment>
//         
    );
  }
}

const mapStateToProps = (state) => {
  return { allItems: state.entities.items.list };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addingNewItem: (item) => dispatch(addNewItem(item)),
    updateItem: (item) => dispatch(updateItem(item)),
    getRackDefinitions: () => dispatch(getRackDefinitions()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewItem); //
