import { createSlice } from "@reduxjs/toolkit";

import { apiCallBegan } from "./api";
import { useSelector } from "react-redux";

//using slice is much more easier than creationg actions and reeducers saperately
const slice = createSlice({
  name: "stockUpate",
  initialState: {
    history: [],
    list: [],
    vendor_names : [],
    loading: false,
    lastFetch: null,
    recent:[]
  },
  reducers: {
    stockUpdateStart: (stockUpate, action) => {
      stockUpate.loading = true;
    },
    stockUpdateFailed: (stockUpate, action) => {
      stockUpate.loading = false;
    },

    stockUpdateSuccess: (stockUpate, action) => {
      stockUpate.list = action.payload
      stockUpate.loading = false;
      stockUpate.lastFetch = Date.now(); // Current time as a timestamp
    },
    stockUpdateGetHistorySuccess: (stockUpate, action) => {
      stockUpate.history = action.payload;
      stockUpate.loading = false;
      stockUpate.lastFetch = Date.now(); // Current time as a timestamp
    },
    stockUpdateGetHistoryFailed: (stockUpate, action) => {
      if (action.payload.includes("404")) {
        stockUpate.history = [
          {
            date: "No history found",
          },
        ];
      }

      stockUpate.loading = false;
    },
    getVendorNamesSuccess: (stockUpate, action) => {
      stockUpate.vendor_names = action.payload;
      stockUpate.loading = false;
      stockUpate.lastFetch = Date.now(); // Current time as a timestamp
    },
    recentStockUpdateSuccess: (stockUpate, action) => {
      stockUpate.recent = action.payload[0].update_date_time
      stockUpate.loading = false;
      stockUpate.lastFetch = Date.now(); // Current time as a timestamp
    }
  },
});
const {
  stockUpdateStart,
  stockUpdateFailed,
  stockUpdateSuccess,
  stockUpdateGetHistorySuccess,
  stockUpdateGetHistoryFailed,
  getVendorNamesSuccess,
  recentStockUpdateSuccess
} = slice.actions;
export default slice.reducer;

//Action Creators

export const updateStock = (data) => (dispatch) => {
  const url = "/medicines/stock";
  dispatch(
    apiCallBegan({
      url: url,
      method: "post",
      data: data,
      onstart: stockUpdateStart.type,
      onSuccess: stockUpdateSuccess.type,
      onError: stockUpdateFailed.type,
    })
  );
};
export const getStockUpdateHistory = (date) => (dispatch) => {
  const url = "/medicines/stock/date/" + date;
  dispatch(
    apiCallBegan({
      url: url,
      method: "get",
      onstart: stockUpdateStart.type,
      onSuccess: stockUpdateGetHistorySuccess.type,
      onError: stockUpdateGetHistoryFailed.type,
    })
  );
};


export const getVendorNames = () => (dispatch) => {
  const url = "/medicines/stock/vendor_names";
  dispatch(
    apiCallBegan({
      url: url,
      method: "get",
      onSuccess: getVendorNamesSuccess.type,
      onError: stockUpdateFailed.type,
      onstart: stockUpdateStart.type,
    })
  );
}

// recent stock update 
export const getRecentStockUpdate = () => (dispatch) => {
  const url = "/medicines/stock/date/recent";
  dispatch(
    apiCallBegan({
      url: url,
      method: "get",
      onSuccess: recentStockUpdateSuccess.type,
      onError: stockUpdateFailed.type,
      onstart: stockUpdateStart.type,
    })
  );
}

// selector
export const SelectStockUpdate = () =>
  useSelector((state) => state.entities.stockUpate);
export const GetStockUpdateHistoryList = () =>
  useSelector((state) => state.entities.stockUpdate.list);
export const GetStockUpdateHistoryHistory = () =>
  useSelector((state) => state.entities.stockUpdate.history);
export const GetVendorNames = () =>
  useSelector((state) => state.entities.stockUpdate.vendor_names);

export const GetRecentStockUpdate = () =>
  useSelector((state) => state.entities.stockUpdate.recent);