import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ReactComponent as DeleteItem } from "../../../images/delete-black-48dp.svg";
import {
  clear_stock_list,
  getAddStockList,
  remove_stock_from_list,
  update_stock_items_in_list,
} from "../../../store/itemsDux";
import History from "./History";
import {
  getStockUpdateHistory,
  updateStock,
} from "../../../store/stockUpdateDuxm.js";
import auth from "../../../services/authService";
import { toast } from "react-toastify";
import Input from "../../common/input.jsx";

export default function AddStockToList({ item }) {
  const dispatch = useDispatch();

  const [user, setUser] = useState(auth.getCurrentUser());

  const [showPopup, setShowPopup] = useState(false);

  const [showHistory, setShowHistory] = useState(false);
  const allStockItems = useSelector(getAddStockList);

  const [updatedStockItems, setUpdatedStockItems] = useState(allStockItems);
  const [billNumber, setBillNumber] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  console.log();
  useEffect(() => {
    // update the model if it remove any elemtnt
    setUpdatedStockItems(allStockItems);
  }, [allStockItems]);

  const handleSubmitStock = () => {
    if (isSubmitDisabled) return;
    // dispatch(update_stock_items_in_list(updatedStockItems));
    console.log("updatedStockItemsList");
    console.log(updatedStockItems);
    if (!updatedStockItems || updatedStockItems.length < 1) return;
    if (!billNumber) return toast.error("Please enter bill number");
    if (!vendorName) return toast.error("Please enter vendor name");

    let missingQTY = false;
    let data = updatedStockItems.map((item) => {
      if (!item.ADDTOSTOCKQTY) {
        missingQTY = true;
        return toast.error(`Please enter QTY for item: ${item.NAME}`);
      }
      return {
        item_id: item.ID,
        item_name: item.NAME,
        vendor_name: vendorName,
        bill_number: billNumber,
        item_qty_before: item.QTY,
        item_qty_to_add: item.ADDTOSTOCKQTY,
        item_qty_after: JSON.stringify(item.TOTALQTY),
      };
    });
    console.log("data");
    console.log(data);
    if (missingQTY) return;
    setIsSubmitDisabled(true);
    dispatch(updateStock(data));
    dispatch(update_stock_items_in_list(data));
    setTimeout(() => {
      setShowPopup(false);
      setUpdatedStockItems([]);
      dispatch(clear_stock_list([]));
      setBillNumber("");
      setVendorName("");
      setIsSubmitDisabled(false);
      window.location.reload();
    }, 2000);
  };

  const handleChangeQty = (event, item) => {
    // Validate input to ensure it's a number
    console.log("event", event.target.value, item);
    const updatedStockList = updatedStockItems.map((i) => {
      if (item.ID == i.ID) {
        let updatedItem = { ...i };
        updatedItem.ADDTOSTOCKQTY = event.target.value;
        updatedItem.TOTALQTY =
          parseInt(updatedItem.ADDTOSTOCKQTY) + parseInt(updatedItem.QTY);
        return updatedItem;
      } else {
        return i;
      }
    });
    setUpdatedStockItems(updatedStockList);
  };

  const removeStockItem = (e, m) => {
    console.log("DELETEDD-----");

    dispatch(remove_stock_from_list(m.ID));

    // hideEditCart();
  };

  // useEffect(() => {
  //   console.log("updatedStockItems>>", updatedStockItems);
  // }, [updatedStockItems]);

  console.log("allStockItem", allStockItems);
  return (
    <div style={{ display: "flex" }}>
      {user.role == "Admin" ? (
        <>
          <button
            style={{ marginLeft: "20px" }}
            name="clearSearchBox"
            className="btn btn-primary btn btn"
            onClick={() => setShowPopup(true)}
          >
            Add to Stock
          </button>
          <div
            style={{
              position: "relative",
              right: "15px",
              bottom: "10px",
            }}
          >
            <div
              style={{
                background: "tomato",
                borderRadius: "50px 50px 50px",
                width: "32px",
                textAlign: "center",
              }}
            >
              {allStockItems?.length}
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <button
        style={{ marginLeft: "20px" }}
        name="history"
        className="btn btn-primary btn btn"
        onClick={() => setShowHistory(true)}
      >
        Stock History
      </button>

      <div>
        <Modal
          className="modal-dialog modal-dialog-centered"
          show={showPopup}
          onHide={() => setShowPopup(false)}
          style={{}}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add bills</Modal.Title>
          </Modal.Header>
          {/* input for add bill number */}
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Input
              type="text"
              label={"Bill Number"}
              placeholder="Enter bill number"
              value={billNumber}
              onChange={(e) => {
                setBillNumber(e.target.value);
              }}
              style={{ width: "200px", height: "25px", margin: "auto" }}
              required
            />
            <Input
              type="text"
              label={"Vendor Name"}
              placeholder="Enter Vendor name"
              value={vendorName}
              onChange={(e) => {
                setVendorName(e.target.value);
              }}
              style={{ width: "200px", height: "25px", margin: "auto" }}
              required
            />
          </div>
          <Modal.Body
            style={{ position: "relative", zoom: "0.75", padding: 0 }}
          >
            <table
              className="table"
              id="table"
              data-toggle="table"
              data-url="json/data5.json"
              data-height="460"
              data-show-columns="true"
            >
              <thead>
                <tr>
                  <th data-field="sq">SQ</th>
                  <th data-field="id">ID</th>
                  <th data-field="name">NAME</th>
                  <th data-field="VENDOR">VENDOR</th>
                  <th data-field="qty">CURRENT QTY</th>
                  <th data-field="qty">QTY TO ADD</th>
                  <th data-field="qty">TOTAL QTY</th>
                  <th data-field="remove-qty">REMOVE</th>
                </tr>
              </thead>
              <tbody>
                {updatedStockItems?.map((m, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{m.ID}</td>
                    <td>{m.NAME}</td>
                    <td>{m.VENDOR}</td>
                    <td>{m.QTY}</td>
                    <td>
                      <input
                        type="number"
                        min={1}
                        max={99}
                        value={
                          updatedStockItems.find((i) => i.ID == m.ID)
                            ?.ADDTOSTOCKQTY
                        }
                        onChange={(e) => handleChangeQty(e, m)}
                        style={{ width: "80px", height: "25px" }}
                      />
                    </td>
                    <td>{m.TOTALQTY}</td>
                    <td>
                      <DeleteItem
                        onClick={(e) => removeStockItem(e, m)}
                        style={{
                          cursor: "pointer",
                          onMouseOver: "this.style.color = 'red'",
                          height: "1.5rem",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleSubmitStock}
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
            <Button variant="secondary" onClick={() => setShowPopup(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <History showHistory={showHistory} setShowHistory={setShowHistory} />
    </div>
  );
}
