import React, { Component, useMemo } from "react";
// import ReactTable from "react-table-v6";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { ReactComponent as ShoppingCart } from "../../../images/shopping_cart.svg";
import { ReactComponent as EditButton } from "../../../images/edit-black.svg";
import { ReactComponent as AddShoppingCart } from "../../../images/add_shopping_cart.svg";
import { ReactComponent as DeleteItem } from "../../../images/delete-black-48dp.svg";
import { ReactComponent as AddNewBatch } from "../../../images/library_add-black-48dp.svg";
import { toast } from "react-toastify";
import _, { padStart } from "lodash";
import MedGroup from "./MedGroup";
import MedGroupList from "./MedGroupList";
import TableColumnFilter from "./TableColumnFilter";
import RackDefinitions from "./RackDefinitionsFun";
import AddNewItem from "./AddNewItem";
import {
  getAllItems,
  updateItem,
  deleteItem,
  getAddStockList,
} from "../../../store/itemsDux";
import { getRackDefinitions } from "../../../store/racksDux";
import { add_item_to_cart } from "../../../store/cartDux";
import { add_stock_to_list } from "../../../store/itemsDux";
import { connect } from "react-redux";
import { getCurrentDateWithDash } from "../../../utils/myDateTime";
import ErrorBoundary from "../../../ErrorBoundary";
import storageService from "../../../services/storageService";
import auth from "../../../services/authService";
import AuthSSE from "../../../services/SSE/AuthSSE";

import AddStockToList from "./AddStockToList";

class NewMedicines extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    user: auth.getCurrentUser(),
    temp: null,
    data: [],
    newBatchData: {},
    columns: [
      ["EDIT", 70],
      ["ADD", 70],
      ["ADDQTY", 70],
      ["ATC", 70],
      ["ID", 60],
      ["NAME", 210],
      ["CONTENT", 460],
      ["RACK_LOCATION", 80],
      ["STOCK_LOCATION", 80],
      ["TYPE", 70],
      ["MANUFACTURER", 140],
      ["BATCH_NO", 100],
      ["EXP", 120],
      ["VENDOR", 140],
      ["STRIPS_IN_BOX", 60],
      ["TABLETS_IN_ONE_STRIP", 60],
      ["QTY", 60],
      ["REORDER_QTY", 60],
      ["MRP", 60],
      ["PURCHASE_PRICE", 60],
      ["SELLING_PRICE", 60],
      ["CREATION_DATE", 110],
      ["DELETE", 70],
    ],
    columnObjects: [],
    adminAccessColumnList: ["DELETE", "QTY", "EDIT"],
    initColumns: [
      "EDIT",
      "ADD",
      "ADDQTY",
      "ATC",
      "DELETE",
      "NAME",
      "CONTENT",
      "RACK_LOCATION",
      "STOCK_LOCATION",
      "TYPE",
      "MANUFACTURER",
      "BATCH_NO",
      "EXP",
      "STRIPS_IN_BOX",
      "TABLETS_IN_ONE_STRIP",
      "MRP",
      "SELLING_PRICE",
    ],
    checkedColumns: [],
    allItems: [],
    allStockItems: [],
    filteredItems: [],
    searchKey: "",
    selected: null,
    selectedRow: null,
    editThis: null,
    editClickCount: 0,
    deleteThis: null,
    deleteClickCount: 0,
    medGroups: [
      //generic
      "ALL",
      "Expired",
      "NearExp",
      "NearOOS",
      "Tab",
      "Cap",
      "Cream",
      "Drops",
      //Eye/Ear Drops,
      //O.drops,
      "General",
      "Syp",
      "Inj",
      "Oint",
      "Sol",
      "Surgical",

      //standard
      //OTC
    ],
    notinlist: ["ALL", "Expired", "NearExp", "NearOOS"],
    selectedMedGroup: "ALL",
    dropDowns: [
      { _id: 1, name: "Soap" },
      { _id: 2, name: "Shampoo" },
      { _id: 3, name: "Spray" },
      { _id: 4, name: "Oil" },
      { _id: 5, name: "ORS" },
      { _id: 6, name: "Powder" },
    ],
    defaultDropDown: { _id: 0, name: "OTC" },
    renderSSE: false,
    showPopup: false,
  };

  insertAllItemsToState = (props) => {
    if (props.allItems?.length > 0) {
      this.setState({ allItems: this.mapToTable(props.allItems) }, () => {
        this.getFilteredItems();
      });
      //console.log(this.state.allItems);
    }
  };

  componentWillReceiveProps(props) {
    this.insertAllItemsToState(props);
    console.log("allStockItems>>", props.allStockItems);
  }

  removeEditDeleteColumns(visibleColumns) {
    return visibleColumns.filter(
      (el) => !this.state.adminAccessColumnList.includes(el)
    );
  }

  setInitColumns = (initColmns) => {
    if (initColmns == null) {
      let visibleColumns = this.state.initColumns;
      if (this.state.user.role !== "Admin") {
        visibleColumns = this.removeEditDeleteColumns(visibleColumns);
      }
      storageService.storeData("initColumns", visibleColumns);
      this.setState(
        {
          checkedColumns: [...visibleColumns],
        },
        () => this.updateColumns()
      );
    } else {
      const columnsArry = initColmns.split(",");
      console.log(columnsArry);

      let visibleColumns = columnsArry;
      if (this.state.user.role !== "Admin") {
        visibleColumns = this.removeEditDeleteColumns(visibleColumns);
      }
      this.setState(
        {
          checkedColumns: visibleColumns,
        },
        () => this.updateColumns()
      );
    }
  };
  componentDidMount() {
    //console.log("Getting All items ASYNC REDUX");
    const initColmns = storageService.getData("initColumns");

    this.setInitColumns(initColmns);
    this.setState({ renderSSE: true });

    console.log("At cdm vefore dispatch for getAllItems>>>>");

    setTimeout(() => this.props.getAllItems(), 5000);
    this.insertAllItemsToState(this.props);
    document.getElementById("SearchBox").focus();
  }

  viewCart = () => {
    console.log(this.props.cart);
  };

  updateQTY = (e) => {
    const temp = e.currentTarget.value;
    this.setState({
      temp,
    });
  };
  handleAddQtyKeyDown = (e) => {
    console.log(e.key);
    if (e.key === "Escape") {
      Array.from(document.getElementsByName("qty")).forEach(
        (input) => (input.value = "")
      );
      this.setState({
        temp: null,
      });
    }
    if (e.key === "Enter") {
      this.addToCart();
    }
  };
  addToCart = (e) => {
    // e.preventDefault();
    if (this.state.selectedRow !== null) {
      let newOrderItem = this.state.selectedRow.original;
      newOrderItem = { ...newOrderItem, ORDER_QTY: this.state.temp };
      if (
        typeof newOrderItem.ORDER_QTY !== "undefined" &&
        newOrderItem.ORDER_QTY !== "" &&
        newOrderItem.ORDER_QTY !== null &&
        newOrderItem.SELLING_PRICE !== "null"
      ) {
        delete newOrderItem.EDIT;
        delete newOrderItem.UPDATE;
        delete newOrderItem.ADD;
        delete newOrderItem.DELETE;
        delete newOrderItem.ATC;
        delete newOrderItem.ADDQTY;
        console.log(newOrderItem);
        if (!this.itemAlreadyInCart(newOrderItem)) {
          //If QTY> ORDER_QTY
          if (parseInt(newOrderItem.QTY) >= parseInt(newOrderItem.ORDER_QTY))
            this.props.add_item_to_cart(newOrderItem);
          //For Redux
          else {
            toast.error(
              `Not enough Item ${newOrderItem.NAME} Left in Stock. Please Add or Select New Batch.`
            );
          }
        } else {
          toast.error(
            `Item ${newOrderItem.NAME} Already added to cart. Please Modify.`
          );
        }
      } else {
        toast.error("Invalid QTY or SELLING_PRICE", { autoClose: 2000 });
        console.log(` QTY invalid`);
      } //${JSON.stringify(m)}
    }
  };

  itemAlreadyInCart = (newOrderItem) => {
    const itemExists = this.props.cart.items.find(
      (ci) => ci.ID === newOrderItem.ID
    );
    if (itemExists) return true;
    else return false;
  };
  onPageChange = () => {
    //Have to reset Input box on page change
    Array.from(document.getElementsByName("qty")).forEach(
      (input) => (input.value = "")
    );
  };
  clearSearchBox = () => {
    document.getElementById("SearchBox").value = "";
    this.setState({ searchKey: "" }, () => this.getFilteredItems());
  };

  updateCheckedColumns = (c) => {
    let checkedColumns = this.state.checkedColumns;
    if (checkedColumns.includes(c)) {
      _.remove(checkedColumns, (ci) => ci === c);
      this.setState({ checkedColumns }, () => {
        storageService.storeData("initColumns", this.state.checkedColumns);
        this.updateColumns();
      });
    } else {
      this.setState({ checkedColumns: _.concat(checkedColumns, c) }, () => {
        storageService.storeData("initColumns", this.state.checkedColumns);
        this.updateColumns();
      });
    }

    //store updated checked columns in storage

    console.log(checkedColumns);
  };

  handleGroupSelect = (item) => {
    this.setState(
      {
        selectedMedGroup: item,
        currentPage: 1,
        selected: null,
        selectedRow: null,
      },
      () => {
        this.getFilteredItems();
        this.onPageChange();
      }
    );
    //console.log(this.state.selectedMedGroup);
  };

  getFilteredItems = () => {
    let { allItems, selectedMedGroup, searchKey } = this.state;
    // let  allItems= this.mapToTable(this.props.allItems);

    let filteredItems =
      selectedMedGroup && !this.state.notinlist.includes(selectedMedGroup)
        ? allItems.filter((m) => m.TYPE === selectedMedGroup)
        : allItems;
    const currentDate = getCurrentDateWithDash();

    switch (selectedMedGroup) {
      case "Expired":
        //(CAST(`medicine_list`.`all_items`.`item_exp` AS DATE) < CURDATE())

        filteredItems = allItems.filter(
          (m) =>
            `${m.EXP.split("-")[2]}-${m.EXP.split("-")[1]}-${
              m.EXP.split("-")[0]
            }` < currentDate
        );
        //ORDER BY `medicine_list`.`all_items`.`item_exp` , `medicine_list`.`all_items`.`item_name` , `medicine_list`.`all_items`.`item_creation_date`
        break;
      case "NearExp":
        //console.log("NearExp");
        filteredItems = allItems.filter(
          (m) =>
            currentDate <=
              `${m.EXP.split("-")[2]}-${m.EXP.split("-")[1]}-${
                m.EXP.split("-")[0]
              }` &&
            `${m.EXP.split("-")[2]}-${m.EXP.split("-")[1]}-${
              m.EXP.split("-")[0]
            }` <=
              currentDate.slice(0, 5) +
                padStart(parseInt(currentDate.slice(5, 7)) + 2, 2, "0") +
                currentDate.slice(7, 10)
        );
        break;
      case "NearOOS":
        //console.log("NearOOS");
        filteredItems = allItems.filter(
          (m) => parseInt(m.QTY) <= parseInt(m.REORDER_QTY)
        );
        break;
      default:
        //console.log("Filter");
        break;
    }
    //console.log("After case Filter");
    filteredItems = filteredItems.filter((obj) =>
      Object.keys(obj).some(function (key) {
        if (obj[key] !== null && typeof obj[key] !== undefined) {
          return obj[key]
            .toString()
            .toUpperCase()
            .includes(searchKey.toUpperCase());
        }
      })
    );
    const filteredLength = filteredItems.length;

    const EDIT = this.EDIT();
    const UPDATE = this.UPDATE();
    const ADD = this.ADD();
    const DELETE = this.DELETE();
    const ADDQTY = this.ADDQTY();
    const ATC = this.ATC();
    const data = filteredItems.map((d) => ({
      EDIT,
      UPDATE,
      ADD,
      ADDQTY,
      ATC,
      ...d,
      DELETE,
    })); // Modify Here when New column added, also in component did mount

    this.setState({ filteredItems, filteredLength, data }); ///, () =>
    //this.getPagedItems()
  };

  handleSearchUpdate = (e) => {
    //console.log(e.currentTarget.value);
    this.setState({ searchKey: e.currentTarget.value }, () =>
      this.getFilteredItems()
    );
  };
  handleSearchKeyDown = (e) => {
    console.log(e.key);
    if (e.key === "Escape") {
      this.clearSearchBox();
    }
  };
  mapToTable = (data) => {
    console.log("data at Map To Table>>");
    console.log(data);
    // console.log("DATA OBject Keys:  ");
    // console.log(Object.keys(data));
    let dataList = data.map((obj) =>
      Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          ...{
            [`${
              key.includes("item_")
                ? key.toUpperCase().substr(5)
                : key.toUpperCase()
            }`]: `${
              typeof obj[key] === "string"
                ? obj[key].includes("000Z")
                  ? obj[key].split("T")[0]
                  : obj[key]
                : obj[key]
            }`,
          },
        }),
        {}
      )
    );
    //updating exp date, creation date formate
    dataList.forEach(function (obj) {
      if (obj["EXP"] !== "null") {
        let expDate = obj["EXP"].split("-");
        obj["EXP"] = `${expDate[2]}-${expDate[1]}-${expDate[0]}`;
      }

      if (obj["CREATION_DATE"] !== "null") {
        let creationDate = obj["CREATION_DATE"].split("-");
        obj[
          "CREATION_DATE"
        ] = `${creationDate[2]}-${creationDate[1]}-${creationDate[0]}`;
      }
    });
    return dataList;
  };

  handleEdit = (rowInfo) => {
    this.setState({ selectedRow: rowInfo });
    if (this.state.editThis === rowInfo.index)
      return this.setState({ editThis: null });
    const editThis = rowInfo.index;
    this.setState({ editThis });
  };
  handleEditSubmit = async (rowInfo) => {
    if (this.state.editThis === rowInfo.index && this.state.editThis !== null) {
      let updateRow = rowInfo.original;
      delete updateRow.EDIT;
      delete updateRow.UPDATE;
      delete updateRow.ADD;
      delete updateRow.DELETE;
      delete updateRow.ADDQTY;
      delete updateRow.ATC;

      let itemArry = updateRow.EXP.split("-");
      updateRow.EXP = `${itemArry[2]}-${itemArry[1]}-${itemArry[0]}`;
      console.log("updateRow : ");
      console.log(updateRow);
      //changing all null strings to values
      Object.keys(updateRow).forEach((k) =>
        updateRow[k] === "null" ? (updateRow[k] = null) : ""
      );

      try {
        this.props.updateItem(updateRow);
        // await httpService.put(`/medicines/${updateRow.ID}`, updateRow);
        // this.setState({ editThis: null, selected: null });
        // let allItems = [...this.state.allItems];
        // allItems[updateRow.ID - 1] = updateRow;
        // this.setState({ allItems });
      } catch (ex) {
        if (ex.response && ex.response.status == 400) {
          toast.error("Only Admin can Edit Items");
          console.log(ex.response);
        } else {
          toast.error("Something's Wrong. Please Try Again");
        }
      }
      toast.success(`Item ${updateRow.NAME} Updated successfully`);
      this.setState({ editThis: null });
    } else {
      this.setState({ editThis: null });
      console.log("not editing");
    }
  };
  handleAddToStock = (row) => {
    //dispatch action to store in redux
    delete row.ADD;
    delete row.ADDQTY;
    delete row.EDIT;
    delete row.ATC;
    delete row.DELETE;
    delete row.UPDATE;

    this.props.add_stock_to_list(row);
  };
  handleEditBatch = (row) => {
    console.log("row>>>>");
    console.log(row);

    this.setState({ editThis: null });
    let newRow = { ...row };
    delete newRow.EDIT;
    delete newRow.UPDATE;
    delete newRow.ADD;
    delete newRow.DELETE;
    delete newRow.ADDQTY;
    delete newRow.ATC;
    delete newRow.ID;
    delete newRow.CREATION_DATE;

    console.log(parseFloat(newRow.QTY) * parseInt(newRow.TABLETS_IN_ONE_STRIP));
    console.log(newRow.QTY);

    console.log(newRow.TABLETS_IN_ONE_STRIP);

    newRow.LOOSE_QTY = (
      parseFloat(newRow.QTY) % parseInt(newRow.TABLETS_IN_ONE_STRIP)
    ).toString();

    console.log("newRow.LOOSE_QTY>>>");
    console.log(newRow.LOOSE_QTY);

    newRow.QTY = parseInt(
      parseFloat(newRow.QTY) / parseInt(newRow.TABLETS_IN_ONE_STRIP)
    ).toString();
    console.log("newRow.QTY>>>");
    console.log(newRow.QTY);

    if (newRow.EXP.split("-")[2].length !== 4) {
      let itemStr = newRow.EXP.split("-");
      newRow.EXP = `${itemStr[2]}-${itemStr[1]}-${itemStr[0]}`;
    }

    this.setState({ newBatchData: newRow });
    console.log("AddnewBatch Here!!");
    console.log(newRow);
  };
  updateNewBatchData = (data) => {
    delete data.CREATION_DATE;
    this.setState({ newBatchData: data });
  };
  updateShowPopup = (data) => {
    this.setState({ showPopup: data });
  };
  handleDelete = (row) => {
    this.setState({ editThis: null });
    console.log(`DELETE COUNT ${this.state.deleteClickCount}`);
    if (this.state.deleteClickCount === 1) {
      const userConfirmation = window.confirm(
        `Confirm Delete of ${row.NAME}??`
      );

      if (userConfirmation) {
        console.log(userConfirmation);
        this.setState({ deleteClickCount: 0 });
        console.log("DELETING..");

        this.props.deleteItem(row.ID);
      } else {
        console.log(userConfirmation);
        this.setState({ deleteClickCount: 0 });
      }
    } else {
      this.setState({ deleteClickCount: this.state.deleteClickCount + 1 });
    }

    //
  };

  onChangeFct = (name) => {
    console.log("onChange usually handled by redux");
    console.log(name);
  };

  EDIT = () => {
    return (
      <div>
        <EditButton
          // onClick={this.handleEdit}
          style={{
            cursor: "pointer",
            onMouseOver: "this.style.color = 'red'",
            height: "1.5rem",
          }}
        />
      </div>
    );
  };

  UPDATE = () => {
    return (
      <div>
        <button
        //  onClick={this.handleEditSubmit}
        >
          Ok
        </button>
      </div>
    );
  };
  ADD = () => {
    return (
      <div>
        <AddNewBatch
          // onClick={this.handleEditBatch}
          style={{
            cursor: "pointer",
            onMouseOver: "this.style.color = 'red'",
            height: "1.5rem",
          }}
        />
      </div>
    );
  };
  DELETE = () => {
    return (
      <DeleteItem
        // onClick={this.handleDelete}
        style={{
          cursor: "pointer",
          onMouseOver: "this.style.color = 'red'",
          height: "1.5rem",
        }}
      />
    );
  };
  ATC = () => (
    <AddShoppingCart
      onClick={(e) => this.addToCart(e)}
      style={{
        cursor: "pointer",
        onMouseOver: "this.style.color = 'red'",
        marginLeft: "20px",
      }}
    />
  );
  ADDQTY = () => (
    <input
      className="qtyAdd"
      id="qty"
      name="qty"
      type="number"
      min="1"
      max="99"
      width="20px"
      placeholder="0"
      onChange={(e) => this.updateQTY(e)}
      onKeyDown={(e) => this.handleAddQtyKeyDown(e)}
    />
  );

  updateColumns = () => {
    let { checkedColumns, columns } = this.state;

    if (this.state.user.role !== "Admin") {
      columns = columns.filter(
        (c) => !this.state.adminAccessColumnList.includes(c[0])
      );
    }

    let columnHeaders = [
      ...columns.map((c) => ({
        name: c[0],
        isVisible: checkedColumns.includes(c[0]),
      })),
    ];

    const columnObjects = columnHeaders.reduce((result, c) => {
      if (
        c.name !== "EDIT" &&
        c.name !== "UPDATE" &&
        c.name !== "ADD" &&
        c.name !== "DELETE" &&
        c.name !== "ID" &&
        c.name !== "ATC" &&
        c.name !== "ADDQTY"
      ) {
        result.push({
          Header: () => <b>{c.name}</b>,
          accessor: `${c.name}`,
          show: c.isVisible,
          width: columns.find((cl) => cl[0] === c.name)[1],
          // getColumnWidth(this.state.data, `${c.name}`),
        });
      } else {
        result.push({
          Header: () => <b>{c.name}</b>,
          accessor: `${c.name}`,
          show: c.isVisible,
          width: 70,
        });
      }
      return result;
    }, []);

    this.setState({ columnObjects });
  };

  render() {
    let { checkedColumns, columnObjects, newBatchData } = this.state;
    const { medGroups, selectedMedGroup, dropDowns, defaultDropDown, data } =
      this.state;
    return (
      <div>
        {/* <div className="row mt-2" style={{ justifyContent: "flex-end" }}> */}
        {/* </div> */}
        <div
          className="row"
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            justifyContent: "space-between",
            minWidth: "1250px",
            flexDirection: "column",
            gridRowGap: "10px",
            display: "grid",
            paddingBottom: "5px",
          }}
        >
          <div style={{ display: "flex" }}>
            <ErrorBoundary>
              <MedGroup
                items={medGroups}
                selectedItem={selectedMedGroup}
                onGroupSelect={this.handleGroupSelect}
                id="MedGroupRef"
              />
            </ErrorBoundary>
            <ErrorBoundary>
              <MedGroupList
                dropDownList={dropDowns}
                defaultValue={defaultDropDown}
                selectedGroup={this.handleGroupSelect}
              />
            </ErrorBoundary>
          </div>

          <div style={{ display: "flex" }}>
            <input
              id="SearchBox"
              className="searchBar"
              type="text"
              width="400px"
              placeholder="Search Anything!!"
              onChange={(e) => this.handleSearchUpdate(e)}
              onKeyDown={(e) => this.handleSearchKeyDown(e)}
            ></input>
            <button name="clearSearchBox" onClick={() => this.clearSearchBox()}>
              CLEAR
            </button>

            <ErrorBoundary>
              <TableColumnFilter
                columns={columnObjects}
                checkedColumns={checkedColumns}
                updateCheckedColumns={this.updateCheckedColumns}
              />
            </ErrorBoundary>
            <>
              <AddStockToList allStockItems={this.props.allStockItems} />
            </>
          </div>
          {/* define_rack */}
          {/* 
          <ErrorBoundary>
            <RackDefinitions />
          </ErrorBoundary>

          <div style={{ display: "flex" }}>
            <ShoppingCart
              onClick={(e) => this.viewCart(e)}
              style={{
                cursor: "pointer",
                onMouseOver: "this.style.color = 'red'",
                margin: "5 5 5 10",
                width: "40px",
                height: "40px",
              }}
            />
            <b>{this.props.cart.items.length}</b>
          </div>
           */}
        </div>
        <ErrorBoundary>
          <ReactTable
            data={data}
            columns={columnObjects}
            className="-striped -highlight"
            onPageChange={this.onPageChange}
            showPaginationTop={true}
            showPaginationBottom={false}
            // pivotBy={["NAME", "ADD"]}
            //Strangly SubComponents Do Not work
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  onClick: (e) => {
                    this.setState({
                      selected: rowInfo.index,
                      selectedRow: rowInfo,
                      temp: null,
                    });
                    this.onPageChange();
                  },
                  className: "editRow",
                  style: {
                    background:
                      rowInfo.index === this.state.editThis
                        ? "rgb(128, 0, 255)"
                        : rowInfo.index === this.state.selected
                        ? "rgb(128,128,128)"
                        : "",
                    color:
                      rowInfo.index === this.state.selected ? "black" : "black",
                  },
                };
              } else {
                return {};
              }
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  console.log(column);
                  if (rowInfo !== undefined) {
                    if (
                      column.id !== "ADD" &&
                      column.id !== "DELETE" &&
                      column.id !== "ATC"
                    )
                      document
                        .getElementsByName("qty")
                        [rowInfo.nestingPath[0]].focus();
                    if (column.id === "ADD") {
                      this.handleAddToStock(rowInfo.original);
                    }
                    if (column.id === "EDIT") {
                      // document.getElementById("BATCH_NO").focus();
                      // update showPopup state to true
                      this.updateShowPopup(true);

                      this.handleEditBatch(rowInfo.original);
                    } else if (column.id === "DELETE")
                      this.handleDelete(rowInfo.original);
                    else if (column.id === "EDIT") this.handleEdit(rowInfo);
                    else if (column.id === "UPDATE")
                      this.handleEditSubmit(rowInfo);
                  }
                  if (handleOriginal) {
                    handleOriginal();
                  }
                },
              };
            }}
          />
        </ErrorBoundary>
        <div className="row">Filtered {this.state.filteredLength} Items.</div>
        {this.state.user.role === "Admin" && columnObjects.length !== 0 ? (
          <div id="addNewItemRef">
            <ErrorBoundary>
              <AddNewItem
                newBatch={newBatchData}
                updateNewBatchData={(batchData) =>
                  this.updateNewBatchData(batchData)
                }
                columns={columnObjects}
                showPopup={this.state.showPopup}
                updateShowPopup={(showPopup) => this.updateShowPopup(showPopup)}
              />
            </ErrorBoundary>
          </div>
        ) : (
          ""
        )}
        {this.state.renderSSE ? <AuthSSE /> : ""}
      </div>
    );
  }
}
//column width based on cell content //https://github.com/tannerlinsley/react-table/issues/94
const getColumnWidth = (rows, accessor) => {
  const maxWidth = 1000;
  const magicSpacing = 10;
  console.log("Column Width:  ");
  console.log(rows);
  console.log(rows.map((row) => `${row[accessor]}`.length));
  const cellLength = Math.max(...rows.map((row) => `${row[accessor]}`.length));
  return Math.min(maxWidth, cellLength * magicSpacing);
};

const mapStateToProps = (state) => {
  return {
    cart: state.entities.cart,
    allItems: state.entities.items.list,
    allStockItems: state.entities.items.add_stock_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    add_item_to_cart: (item) => dispatch(add_item_to_cart(item)),
    //TODO dispatch for addtostock
    add_stock_to_list: (item) => dispatch(add_stock_to_list(item)),
    getAllItems: () => dispatch(getAllItems()),
    updateItem: (item) => dispatch(updateItem(item)),
    deleteItem: (id) => dispatch(deleteItem(id)),
    getRackDefinitions: () => dispatch(getRackDefinitions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMedicines);
